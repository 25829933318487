.toggleClick {
    cursor: pointer;
}

.cardHeader {
    background-color: rgb(35, 128, 191);
    color: white;
    cursor: pointer;
}

.nameHeader {
    float: left;
}

.clinicName1 {
    font-weight: bold;
    font-size: x-large;
}

.clinicName2 {
    font-size: medium;
}

.cardHeaderTitle {
    font-weight: bold;
}

.MATClinic {
    background-color: #dae5f9;
}

.MATMessage {
    font-size: 20;
    font-weight: bold;
    margin-bottom: 5;
    color: rgb(250, 250, 250);
}

.summaryCard {
    margin-bottom: 10px;
}

.moreLink {
    float: right;
    color: white;
}

.mapLink {
    background-color: rgb(238, 251, 255);
    padding: 5px;
}

.mapLink a {
    color: black;
    text-decoration: none;
    padding-left: 5px;
}

.mapLinkIcon {
    color: deepskyblue;
}

.clinicPhone {
}

.clinicPhoneIcon {
    padding: 10px;
    color: black;
}

.clinicWebsite {
    text-decoration: none;
    font-size: medium;
}

.clinicAddress {
    color: gray;
    text-decoration: none;
}

.serviceLabel {
    font-weight: bold;
}

.serviceString {
    font-weight: normal;
}

.paymentTypeLabel {
    font-weight: bold;
}

.paymentTypeString {
    font-weight: normal;
}
