.resultHeader {
    padding: 10px;
    font-weight: bold;
    color: blue;
}

.instructionHeader {
    font-size: x-large;
    font-weight: bold;
    text-align: center;
    padding: 10px;
}

.instructionText {
    padding: 20px;
    text-align: justify;
}

.languageCard {
    margin-top: 20px;
}

.languageOptionHeader {
    font-weight: bold;
    font-size: small;
    float: left;
    margin-left: -10px;
}

.suggestion-item {
    cursor: pointer;
    background-color: gray;
}

.suggestion-item-active {
    cursor: pointer;
    background-color: cyan;
}
