.serviceHeader {
    text-transform: uppercase;
    font-size: small;
    font-weight: bold;
    margin-bottom: 5px;
}

.missingServiceData {
    font-size: small;
    font-weight: bold;
    margin-left: 50px;
    margin-top: 20px;
}

.serviceGroup li {
    font-size: small;
}
