.optionLabel {
    margin-top: 1px;
}

.optionTag {
    margin-top: 5px;
    font-weight: bold;
    font-size: smaller;
}

.optionDescription {
    font-size: x-small;
    font-weight: normal;
    padding-left: 2px;
}
