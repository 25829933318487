.toggleClick {
    cursor: pointer;
}

.optionCard {
    margin-top: 20px;
    cursor: pointer;
}

.optionHeader {
    font-weight: bold;
    font-size: small;
    float: left;
    margin-left: -10px;
}

.collapseButton {
    float: right;
}

.collapseIcon {
    font-weight: normal;
    color: black;
    font-size: small;
    float: right;
    margin-top: 5px;
    margin-right: -10px;
}
