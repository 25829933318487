.clinicName1 {
    font-weight: bold;
    font-size: x-large;
}

.clinicName2 {
    font-weight: normal;
    font-size: large;
}

.clinicWebsite {
    text-decoration: none;
    font-size: small;
}

.clinicWebsite :hover {
    text-decoration: none;
}

.clinicPhone {
    float: right;
    font-weight: bold;
    font-size: large;
}

.clinicPhoneIcon {
    padding: 10px;
    color: blue;
}

.clinicDirectionsIcon {
    padding: 10px;
    color: black;
}

.carIcon {
    padding: 10px;
    color: cyan;
}

.clinicCreditCardIcon {
    padding: 10px;
    color: yellow;
}

.mainCard {
    margin-top: 50px;
    margin-bottom: 50px;
    height: 550px;
}

.mainCardHeader {
    font-weight: bold;
    font-size: large;
    background-color: white;
}

.mainCardFooter {
    height: 80px;
    background-color: white;
}

.locationHeader {
    float: left;
}

.clinicDistanceHeader {
    float: right;
    font-weight: normal;
    color: darkgray;
}

.addressGroup {
    margin-top: 10px;
    float: left;
}

.address {
    font-weight: bold;
}

.mapBox {
    height: 270px;
    width: 100%;
}

.directionsButton {
    margin-top: 10px;
    float: right;
    color: black;
    background-color: white;
}

.paymentAccepted {
    list-style-type: none;
}

.paymentAccepted li {
    padding: 5px;
}

.transportationAssistance {
    font-weight: bold;
}

.instructions {
}

.checkIcon {
    font-weight: bold;
    padding: 5px;
    color: green;
}
