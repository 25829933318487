.spinner {
    position: absolute;
    top: 150px;
    margin: auto;
    left: 0;
    right: 0;
    width: 5rem;
    height: 5rem;
    z-index: 2000;
}
